<template>
  <div class="relative">
    <div class="flex flex-col pb-10 pt-11 lg:container lg:justify-center lg:pb-16 lg:pt-24">
      <div class="mb-1 flex flex-col lg:w-2/5">
        <UiIcon
          v-if="props?.icon"
          :name="props.icon"
          lazy-img
          class="mb-2.5 w-36 lg:w-60"
        />
        <UiHeader
          :header="props.header"
          :header-layout="2"
          header-style-class="mb-6 text-xl lg:text-4xl"
        />
        <p
          v-if="props?.subheader"
          class="text-primary"
        >
          {{ props.subheader }}
        </p>
        <UiButton
          v-if="props.button?.link?.href"
          :to="props.button.link.href"
          variant="secondary"
          slim
          class="mt-6 w-full lg:mt-10 lg:w-auto lg:max-w-60"
        >
          {{ props.button.text }}
        </UiButton>
      </div>
      <div
        ref="wrapper"
        data-el="video-wrapper"
        class="z-0 -mx-8 h-full lg:absolute lg:inset-y-0 lg:mx-0 lg:min-h-0"
      >
        <video
          v-if="props.promoVideo?.publicUrl"
          width="100%"
          height="100%"
          :autoplay="Boolean(props.promoVideo?.properties?.autoplay)"
          muted
          loop
          class="size-full object-cover"
        >
          <source
            :src="props.promoVideo.publicUrl"
            :type="props.promoVideo?.properties?.mimeType"
          >
        </video>
      </div>

      <div class="custom-scrollbar--transparent z-10 -mr-8 mt-2 flex flex-nowrap gap-4 overflow-x-auto lg:mt-11 lg:overflow-x-hidden">
        <UiVideoShowcaseItem
          v-for="(video, index) in props.mappedVideos"
          :key="index"
          :video="video"
          @click="emit('click', index)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'
import { ref, watch } from 'vue'
import { RawlplugUiMqKey } from '../../../plugin'
import type { RawlplugUiMq } from '../../../plugin'
import UiHeader from '../../UiHeader/UiHeader.vue'
import UiButton from '../../UiButton/UiButton.vue'
import UiIcon from '../../UiIcon/UiIcon.vue'
import UiVideoShowcaseItem from '../UiVideoShowcaseItem/UiVideoShowcaseItem.vue'
import type { T3CeRawlplugShowcaseProps } from '../../../../rawlplug-cms/components/T3CeRawlplugShowcase/T3CeRawlplugShowcase.types'

const props = defineProps<Partial<T3CeRawlplugShowcaseProps>>()
const emit = defineEmits(['click'])
const { isMobile } = (inject(RawlplugUiMqKey) as RawlplugUiMq) || {}
const wrapper = ref<HTMLDivElement>()
const { width } = useWindowSize()

function setVideoWrapperWidth() {
  const element = wrapper.value
  if (!element) { return }

  const { x } = element.getBoundingClientRect()

  if (!isMobile.value) {
    const wrapperWidth = document.body.clientWidth - x
    element.style.transform = 'translateX(0)'
    element.style.width = `${wrapperWidth}px`
    return
  }

  if (x !== 0) {
    element.style.transform = `translateX(${-x}px)`
  }

  element.style.width = `${document.body.clientWidth}px`
}

watch([width, isMobile], () => {
  setVideoWrapperWidth()
})

onMounted(() => {
  setVideoWrapperWidth()
})
</script>

<style lang="postcss" scoped>
@media screen(lg) {
  [data-el='video-wrapper'] {
    left: 46%;
    width: 50vw;
  }
}
</style>
